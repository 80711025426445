// import { Container } from "react-bootstrap";


function ShowVideo(props){
    return(<div>
    {/* <Container style={{textAlign:"center", margin:"50px"}}> */}
        <iframe class="video_height"
        // src='https://www.youtube.com/embed/efMWrmYzds4'
        src={props.src}
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        title={props.title}
        width="100%"
        // height="56%"
        style={{border:"1px solid black"}}
/>
 {/* </Container> */}
    </div> )
}
export default ShowVideo;