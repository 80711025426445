function ShowImageUnorderedList(props){
    return(        
    <ul>
    {Object.entries(props.images).map(([key, value]) =>
  
      <li key={key}>
      <button>
      <img src = {props.images[key]} alt={props.images[key]} style={{objectFit:"cover"}}></img>
      
   
        </button>
      </li>
  

    )}
  </ul>
)
}

export default ShowImageUnorderedList;