import GalleryImage from "./GalleryImage";
function ShowLargeImage(props){
    return(        
    <div className="gallery" >
    {Object.entries(props.images).map(([key, value]) =>
      <GalleryImage
        key={key}
        index={key}
        src={props.images[key]}
        className={"galleryImage"}
        height={props.height}
        width={props.width}
        //handleClick={handleOutdoorImageClick}
        handleClick={props.handleClick}

      />

    )}
  </div>
)
}

export default ShowLargeImage;

