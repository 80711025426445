function LargeImageModal(props) {
    return (
        
        <div className="modall" >
        {console.log(props.image)}
                <span className="close" onClick={props.onClose}>&times;</span>
                {/* <h2>largeImageModal</h2> */}
            
            <img src={props.image} className="modal-content" alt="" />
            


        </div>


    );
}
export default LargeImageModal;