import Payment from "../assets/credit-card.png";
import ContactForm from "../assets/contact-form.png"
import { Container } from "react-bootstrap";

function Register() {
  return (
    <div className="registerDiv">
      <h1> 欢迎加入雷丁中文学校大家庭！</h1>
      <h1>   Welcome to Reading Chinese School</h1>

      <Container className="registerStepDiv">

      <img src={ContactForm} alt="" height={"45px"} /><span> Registration Form 注册表格 </span>
        <div className="regpayment">


        <p>请填写注册表格： <a href= "https://forms.gle/ihozRx7EDdojjitK9"  target="_blank"  rel="noopener noreferrer"> Registration Form </a> </p>


        <p>All students must submit online application form. Please note we no longer accept paper form.</p>

        <p>所有学生必须提交网上报名表格。请注意，我们学校已不再接受纸质申请表。</p>
       

      </div>
      </Container>
      
      <Container className="registerStepDiv">

        <img src={Payment} alt="" height={"45px"} /><span > Payment 学费 </span>
        <div className="regpayment">

        <p className="paymentMethod">Bank transfer to school account 转账至学校账号 </p>

        <p>Bank account name:  Reading Chinese School</p>
        <p>Bank: Lloyds bank</p>
        <p>Sort code: 30 96 96</p>
        <p>Account number: 01402495</p>

        <p>Reference 备注:</p>
        <p>Please use class name and student First Name+Last Name</p>
        <p>学生班级和学生名字+姓</p>
        <p>Eg 例: M1B-AmyLi</p>
        <p>请保留付款截图开学时交给老师</p>
        <hr ></hr>


        <p className="paymentMethod">Cash or Cheque Payment 现金或支票支付</p>
        <p>Please deposite cash or cheque at your bank, cheque payable to Reading Chinese School.</p>
        <p>Please ask staff to add payment reference "class name and student First Name+Last Name".</p>
        <p>现金和支票请自己到银行存入，请柜台工作人员在存款凭证上备注好孩子的班级和姓名，开学时将存款凭证交给班级老师。</p>
        {/* <hr></hr>

        <div className="paymentMessage">
          <p>
          Please keep screenshot of online payment as evidence and send the screenshot to class teacher. 
          网上付费请截屏并发给班主任。</p>
          </div> */}
          </div>


        </Container>

        <Container>
        <div className="paymentMessage">
        <p>
          Please keep screenshot of online payment or bank deposit evidence and send to class teacher. 
          网上付费请截屏并发给班级老师，银行存款请保留凭证交给班级老师。</p>
          Withdrawal & Refund Policy: <br />



Students can withdrawal at any time, however refund of school fee will be only given if the withdrawal request was made to the school within the first 6 weeks. The refund will be 5/6 of the paid fee deducted the admin charge which is 20 pounds.

        <br />退学以及退费规则：<br />



        学生家长可以在任何阶段提出退学请求。学费不退返，除非是在第一个学期的 中期假期（half term）之前退学的， 退费金额则为全年学费的 5/6， 再扣除退学手续费 £20。



          
        </div>
        </Container>


   





    </div>
  );
}

export default Register;
