import { BrowserRouter, Routes, Route } from "react-router-dom"

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import About from "../pages/About";
import Home from "../pages/Home";
import Register from "../pages/Register";
import Resources from "../pages/Resources";
import Events from "../pages/Events";
import Courses from "../pages/Courses";

//import GalleryNew from "./GalleryNew";
import ErrorPage from "../pages/ErrorPage";
// import ShowVideo from "./ShowVideo";
//put here after all imports
import GalleryNew from "../pages/GalleryNew";
//const GalleryNew = lazy(() => import('./GalleryNew'));

function App() {



  return (
    <div className="App">
      <BrowserRouter >
        <Header />
    
          <div>
            <Routes>
              <Route path="/*" element={<ErrorPage />} />
              <Route path="/" element={<Home />} />
              <Route path="/courses" element={<Courses init={{ calendar: true }} />} />
              {/* <Route index={true} element={<Courses init={{calendar:true}}/>} />
          
          <Route path="/courses/school-calendar.html" element={<Courses init={{calendar:true}}/>} />
          </Route>          */}

              
              <Route path="/courses/extra" element={<Courses init={{ extra: true }} />} />
              <Route path="/courses/chinesetest" element={<Courses init={{ yct: true }} />} />
              <Route path="/courses/school-calendar.html" element={<Courses init={{ calendar: true }} />} />
              <Route path="/classes.html" element={<Courses init={{ classes: true }} />} />
              <Route path="/rcs-data-protection-policy.html" element={<About init={{ policy: true }} />} />


              {/* <Route path="/courses:calendar" element={<Courses init={{calendar:true}}/>} />  
          <Route path="/courses:classes" element={<Courses init={ {classes:true}}/>} />   
          <Route path="/courses:extra" element={<Courses init={{extra:true}}/>} />  
          <Route path="/courses:yct" element={<Courses init={ {yct:true}}/>} />  
          <Route path="/courses:curriculum" element={<Courses init={ {curriculum:true}}/>} />    */}



              <Route path="/gallery" element={<GalleryNew />} />
              <Route path="/register.html" element={<Register />} />
              <Route path="/events" element={<Events />} />
              <Route path="/rescources" element={<Resources />} />
              <Route path="/contact" element={<About init={{ profile: true }} />} />
              {/* <Route path="/about" element={<About />} /> */}
              {/* <Route path="/video" element={<ShowVideo />} /> */}


            </Routes>
          </div>
   
        <Footer />

      </BrowserRouter >

    </div>
  );
}

export default App;
