import { createTheme } from "@mui/material";

const customTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#9B1119",
            color: "#fff",
            '&:hover': {
              backgroundColor: '#fff',
              color: "#9B1119"
            },
            fontSize: "1rem",
          }
        }
      }
    }
  });

  export default customTheme;