

function GalleryImage (props){

    function clickHandler(){
        if(props.handleClick){
            // console.log("GalleryImage clicked")
            // console.log("props "+ props.index)
            // console.log("props "+ props.width)
        props.handleClick(props.index);
         }
    }
    // return(<div /*className="min-vh-100"*/ >
    //     <img className="galleryImage " /*className="d-block w-100"*/
    //         src={props.src}
    //         width={props.width}
    //         height= {props.height}
    //         onClick = {clickHandler}

    //     />
    //     <div className="sizedBox"></div>
    // </div>)

    return(
        // <div className="imageDiv">
        <img className={props.className  } /*className="d-block w-100"*/  
            src={props.src}
            alt={""}
            width={props.width}
            height= {props.height}
            onClick = {clickHandler}

        />
        // </div>
        )
 
}
export default GalleryImage;