
import { Container, Row } from "react-bootstrap";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import RCSText from "../assets/header_text.png"

function Footer() {
    let s = new Date().getFullYear();

    //console.log(s);

    return (
        <Container fluid className="footer">
            <Container className="footerContactContainer">

                <Row>
                    <div className="col-md-4">
                        <div>
                            <LocationOnIcon />
                            {/* <h4 class="contact-title">Address</h4> */}
                            <p>
                                Highdown School, Surley Row, <br />
                                Emmer Green,   Reading, RG4 8LR
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            <PhoneEnabledIcon />
                            
                            <p></p>
                            {/* <h4 class="contact-title">Phone Number</h4> */}
                            <p >
                                07827961884
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            {/* <h4 class="contact-title">Email Address</h4> */}
                            <EmailOutlinedIcon />
                            <p></p>
                            <p >
                                readingchineseschool@hotmail.com
                            </p>

                        </div>
                    </div>

                </Row>
            </Container>

            <img src={RCSText} className="footerImage"  alt="Reading Chinese School" /*style = {{ width: "25%", maxWidth:"25%"}}*/></img>
            <h4>Reading Chinese School</h4>
            <h5>Charity No: 1163869</h5>
            {/* <div className="attributeSpan"> Icons made by Freepik from www.flaticon.com </div>  */}
            <p > ©  {s} Reading Chinese School. All Rights Reserved  </p> <div className="attributeSpan"> Icons made by Freepik from www.flaticon.com </div> 
            
        </Container>
    )
}
export default Footer;