import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import mainLogo from '../assets/RCSLOGO.png';

// import { useState } from 'react';


function Header() {

// const [id, setID] = useState("HOME");
// const [isVisible, setVisible]= useState(window.innerWidth < 996);
// function  handleActive(id){
//   setVisible(window.innerWidth < 996);
//   setID(id);
// }

  return (

    <Navbar sticky="top" data-bs-theme="light" expand="lg" >
      <div    className='headerContainer navbar'>
      <Navbar.Brand href="/" aria-label="home">
          <img
            alt=""
            src={mainLogo}
           // width="70"
            height="70"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
      <Navbar.Text className=" d-inline-flex flex-column align-items-center">
          <span className="navbartextBold">Reading&nbsp;Chinese&nbsp;School </span>
          <span className="navbartextBold">   雷丁中文学校         </span>
        </Navbar.Text>




        
        <Navbar.Toggle className="navToggle" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav
            className="navbar-nav ms-auto me-auto"
            style={{ maxHeight: '300px' }}
            navbarScroll
          >
            <Nav.Link  href="/" /*onClick={()=>handleActive("HOME")}*/>HOME</Nav.Link>  
            <Nav.Link  href="/courses" >COURSES</Nav.Link>  
            {/* <Nav.Link  href="/calendar" >CALENDAR</Nav.Link>   */}
            <Nav.Link  href="/register.html">REGISTER</Nav.Link>
            <Nav.Link  href="/events">EVENTS</Nav.Link>
            <Nav.Link  href="/gallery">GALLERY</Nav.Link>  
            <Nav.Link  href="/rescources">RESOURCES</Nav.Link>
            <Nav.Link  href="/contact">ABOUT&nbsp;US</Nav.Link>
            {/* <Nav.Link  href="/gallerytest">GALLERYTEST</Nav.Link> */}
            {/* <Nav.Link  href="/video">VIDEO</Nav.Link> */}
          </Nav>
          </Navbar.Collapse> 
          {/* {console.log(window.innerWidth > 1000)}
      <div className="pagInfoDiv" style={{visibility: isVisible? "visible": "hidden"}}>
        <h1>{id}</h1>
      </div>
        */}
      </div>
    </Navbar>
  );
}
export default Header;


/* <Nav.Link as={NavLink} to="/about">ABOUT&nbsp;US</Nav.Link> */




/* 

        <Nav.Link as={NavLink}  to="/"  data-bs-target=".navbar-collpse.show" data-bs-toggle="collapse">HOME</Nav.Link>

        <Nav.Link className='nav-link' href="/courses" data-bs-target=".navbar-collpse.show" data-bs-toggle="collapse">COURSES</Nav.Link>

            {/* <NavDropdown  as={NavItem} style={{textDecoration: "none"}}title="COURSES"  id="basic-nav-dropdown">
              <NavDropdown.Item  href="/courses:calendar">Calendar</NavDropdown.Item>
              <NavDropdown.Item href="/courses:classes">Classes</NavDropdown.Item>
              <NavDropdown.Item href="/courses:curriculum">Curriculum Plan</NavDropdown.Item>              
              <NavDropdown.Item href="/courses:yct">Chinese Proficiency Test</NavDropdown.Item>
              <NavDropdown.Item href="/courses:extra">Extracurricular & Library</NavDropdown.Item>
            </NavDropdown> */
   
            // <Nav.Link className='nav-link' href="/gallery">GALLERY</Nav.Link>  

              /* <Nav.Link  className={`${id === 'course' ? "activeLink nav-link " : "nav-link  "}`} onClick={(e)=>{handleActive(e, "course")}} href="/courses">COURSES</Nav.Link>
            <Nav.Link className={`${id === 'gallery' ? "activeLink nav-link " : "nav-link  "}`} onClick={(e)=>{handleActive(e, "gallery")}}  href="/gallery" >GALLERY</Nav.Link> */
            // <Nav.Link  className='nav-link' href="/register">REGISTER</Nav.Link>
            // <Nav.Link  className='nav-link' href="/events">EVENTS</Nav.Link>
            // <Nav.Link  className='nav-link' href="/rescources">RESOURCES</Nav.Link>
            // <Nav.Link className='nav-link' href="/about">ABOUT&nbsp;US</Nav.Link>

     