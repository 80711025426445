import { Container } from "react-bootstrap";
import React, { useRef, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import GalleryImage from "./GalleryImage";

function CarouselThumbNails(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const inner = useRef(null);
  var tempindex = 0;


  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        onPrevClicked();
      } else {
        onNextClicked();
      }
    }
    // add your conditional logic here
  }

  function changeSlide(position) {
    // console.log("changeSlide to:" + position)
    Array.from(inner.current.children).forEach((el, i) => {
      if (i !== position) {
        el.classList.remove("active");
      } else {
        el.classList.add("active");
        setCurrentSlide(position);
      }
    });
  };

  function onPrevClicked() {
    const items = Array.from(inner.current.children);
    var newIndex = currentSlide - 1;
    if (newIndex === -1) {
      newIndex = items.length - 1;
    }
    changeSlide(newIndex);
  }

  function onNextClicked() {
    const items = Array.from(inner.current.children);
    var newIndex = currentSlide + 1;
    if (newIndex === items.length) {
      newIndex = 0;
    }
    changeSlide(newIndex)
  }
  function resetIndex() {
    tempindex = 0;
  }

  function handleClick(index) {
    changeSlide(index);
  }
  // function createThumbNails(key, value){

  //     index = index + 1;
  //     return (<img className={`carousel-indicator ${currentSlide === index - 1 ? "active" : ""}`}
  //       onClick={() => changeSlide(index - 1)}
  //       src={props.thumbnails[key]}
  //       style={{ width: "100px", height: "100px" }} ></img>)
  //   }


  return (
    <Container className="carouselContainer" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} >

      <Container className="carousel-inner " ref={inner} >


        {Object.entries(props.images).map(([key, value]) => {

          tempindex = tempindex + 1;


          return (<Carousel.Item key={key} className={tempindex === 1 ? "active carouselImage " : "carouselImage "}>
            <img   /*src={props.images[key]}*/ src={value} alt=""></img>
          </Carousel.Item>)
        })}



      </Container>


      <button className="carousel-control-prev" type="button" /*data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"*/ onClick={onPrevClicked}>
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" /*data-bs-target="#carouselExampleIndicators" data-bs-slide="next"*/ onClick={onNextClicked}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>

      <div className="carousel-indicators"  >

        {resetIndex()}
        {/* {Object.entries(props.thumbnails).map(([key, value]) => {

{console.log("index = " + index)}

          index = index + 1;
          return (<img className={`carousel-indicator ${currentSlide === index - 1 ? "active" : ""}`}
            onClick={() => changeSlide(index - 1)}
            src={props.thumbnails[key]}
            style={{ width: "100px", height: "80px" }} ></img>)
        }
        )} */}

        {Object.entries(props.thumbnails).map(([key, value]) => {


          tempindex = tempindex + 1;

          return (<GalleryImage key={key} className={`${currentSlide === tempindex - 1 ? "active indicatorImage " : "indicatorImage  "}`}
            index={tempindex - 1}

            handleClick={handleClick}
            // src={props.thumbnails[key]}
            src={value}
            ></GalleryImage>)


        }
        )}


      </div>


      {/* <div className="carousel-indicators" style={{ marginBottom: "-50px" }} >
        <button
          className={`carousel-indicator ${currentSlide === 0 ? "active" : ""}  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" `}
          onClick={() => changeSlide(0)}
          style={{ width: "100px" }}
        >
          <img
            className="d-block w-100 img-fluid"
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(88).webp"
          />
        </button>
        <button
          className={`carousel-indicator ${currentSlide === 1 ? "active" : ""} data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"`}
          onClick={() => changeSlide(1)}
          style={{ width: "100px" }}
        >
          <img
            className="d-block w-100 img-fluid"
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(121).webp"
          />
        </button>
        <button
          className={`carousel-indicator ${currentSlide === 2 ? "active" : ""} ata-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"`}
          onClick={() => changeSlide(2)}
          style={{ width: "100px" }}
        >
          <img
            className="d-block w-100 img-fluid"
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(31).webp"
          />
        </button>
      </div> */}

    </Container >
  )
};
export default CarouselThumbNails;






/* <Carousel onSlide={slideChanged} >

      {console.log("temp:")}
      {console.log(temp)}
      {console.log(temp.current)}
      {console.log("slider:")}
      {console.log(slider)}
      {console.log(slider.current)}
      <div ref={temp}>
      <Carousel.Item className="active">
        <img
          src="https://mdbootstrap.com/img/Photos/Slides/img%20(88).webp"
          alt="..."
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          src="https://mdbootstrap.com/img/Photos/Slides/img%20(121).webp"
          alt="..."
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          src="https://mdbootstrap.com/img/Photos/Slides/img%20(31).webp"
          alt="..."
        />
      </Carousel.Item>
</div>
  </Carousel> */

/* <div /*id="carouselExampleIndicators"*/ /*class="carousel slide" data-bs-ride="carousel" > */


/* <Carousel.Item className="active">
   <img
     src="https://mdbootstrap.com/img/Photos/Slides/img%20(88).webp"
     alt="..."
   />
 </Carousel.Item>

 <Carousel.Item>
   <img
     src="https://mdbootstrap.com/img/Photos/Slides/img%20(121).webp"
     alt="..."
   />
 </Carousel.Item>

 <Carousel.Item>
   <img
     src="https://mdbootstrap.com/img/Photos/Slides/img%20(31).webp"
     alt="..."
   />
 </Carousel.Item> */

/*
          <div className="carousel-indicators" style={{ marginBottom: "-50px" }} >
          <button
            className={`carousel-indicator ${currentSlide === 0 ? "active" : ""}  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" `}
            onClick={() => changeSlide(0)}
            style={{ width: "100px" }}
          >
            <img
              className="d-block w-100 img-fluid"
              src="https://mdbootstrap.com/img/Photos/Slides/img%20(88).webp"
            />
          </button>
          <button
            className={`carousel-indicator ${currentSlide === 1 ? "active" : ""} data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"`}
            onClick={() => changeSlide(1)}
            style={{ width: "100px" }}
          >
            <img
              className="d-block w-100 img-fluid"
              src="https://mdbootstrap.com/img/Photos/Slides/img%20(121).webp"
            />
          </button>
          <button
            className={`carousel-indicator ${currentSlide === 2 ? "active" : ""} ata-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"`}
            onClick={() => changeSlide(2)}
            style={{ width: "100px" }}
          >
            <img
              className="d-block w-100 img-fluid"
              src="https://mdbootstrap.com/img/Photos/Slides/img%20(31).webp"
            />
          </button>
        </div> */


/* <Carousel data-bs-theme="light" defaultActiveIndex={index} pause= "true" >


 {Object.entries(props.thumbnails).map(([key, value]) => {


   index = index + 1;
   return (<Carousel.Item>
   <img className={`carousel-indicator ${currentSlide === index - 1 ? "active" : ""}`}
onClick={() => changeSlide(index - 1)}
src={props.thumbnails[key]}
style={{ width: "100px", height:"80px"}} ></img></Carousel.Item>)
 }
 )} 
</Carousel> */