
function ErrorPage() {
   
    return (

      <div  style={{margin: "200px 50px"}}>
        

        {/* <h1>Page Not Found!</h1> */}
        </div>
    );
}
export default ErrorPage;
  
  