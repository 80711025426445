import { Table } from "react-bootstrap";

function SchoolClasses() {
    return (
        <Table className="coursesTable" striped>
            <thead>
                <tr>
                    <th>班级 Class</th>
                    <th>教师/助教 Staff</th>
                    <th>教材 Textbook</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>亲子班 Parents&Toddler</td>
                    <td>程琳/付珊</td>
                    <td>自编</td>
                </tr>
                <tr>
                    <td>幼儿A班 Recption A</td>
                    <td>耿金燕/唐丽</td>
                    <td>快乐幼儿华语</td>
                </tr>
                <tr>
                    <td>幼儿B班 Recption B</td>
                    <td>邱瑾/杨宁</td>
                    <td>快乐幼儿华语</td>
                </tr>
                <tr>
                    <td>M1A</td>
                    <td>马晓婷/杨华</td>
                    <td>快乐幼儿华语</td>
                </tr>
                <tr>
                    <td>M1B</td>
                    <td>朱琳/钱晓云</td>
                    <td>快乐幼儿华语</td>
                </tr>
                <tr>
                    <td>M2A</td>
                    <td>贺俊玲/毕翔</td>
                    <td>《中文》第一册</td>
                </tr>
                <tr>
                    <td>M2B</td>
                    <td>杨慧/王芳芳</td>
                    <td>《中文》第一册</td>
                </tr>
                <tr>
                    <td>M3A</td>
                    <td>张艳荣/李娜</td>
                    <td>《中文》第二册</td>
                </tr>
                <tr>
                    <td>M3B</td>
                    <td>吴筱瑛/黄淑君</td>
                    <td>《中文》第二册</td>
                </tr>
                <tr>
                    <td>M4</td>
                    <td>张蕾/何燕</td>
                    <td>《中文》第三册</td>
                </tr>
                <tr>
                    <td>M5A</td>
                    <td>汤丽娜/李艳珍</td>
                    <td>人教版小学语文</td>
                </tr>
                <tr>
                    <td>M5B</td>
                    <td>黄秋香</td>
                    <td>《中文》第四册</td>
                </tr>
                <tr>
                    <td>M6</td>
                    <td>郑秀华</td>
                    <td>《中文》第五册</td>
                </tr>
                <tr>
                    <td>M7</td>
                    <td>王晓丽/温玉霞</td>
                    <td>《中文》教材和HSK4</td>
                </tr>
                <tr>
                    <td>Advanced</td>
                    <td>张纲纲</td>
                    <td>自编</td>
                </tr>
                <tr>
                    <td>YCT</td>
                    <td>张静媛</td>
                    <td>Sentence Builder</td>
                </tr>
                <tr>
                    <td>CIE Exam</td>
                    <td>刘永勤/王克澜</td>
                     <td>CIE教材 </td>
                </tr>
                <tr>
                    <td>CIE Non-Exam</td>
                    <td>王彩云/杨松</td>
                    <td>CIE教材</td>
                </tr>
                <tr>
                    <td>A level</td>
                    <td>姜红敏</td>
                    <td>自编</td>
                </tr>
                <tr>
                    <td>Adult</td>
                    <td>洪燕芳</td>
                    <td>自编</td>
                </tr>
            </tbody>

            {/* <tbody>
                <tr>
                    <td>亲子班 Mother&Toddler</td>
                    <td>王芳芳/付珊</td>
                    <td>自编</td>
                </tr>
                <tr>
                    <td>幼儿A班 Recption A</td>
                    <td>马晓婷/杨华</td>
                    <td>快乐幼儿华语</td>
                </tr>
                <tr>
                    <td>幼儿B班 Recption B</td>
                    <td>张艳荣/温玉霞</td>
                    <td>快乐幼儿华语</td>
                </tr>
                <tr>
                    <td>M1A</td>
                    <td>贺俊玲/毕翔</td>
                    <td>快乐幼儿华语</td>
                </tr>
                <tr>
                    <td>M1B</td>
                    <td>郝鑫/杨宁</td>
                    <td>快乐幼儿华语</td>
                </tr>
                <tr>
                    <td>M2A</td>
                    <td>耿金燕/李娜</td>
                    <td>《中文》第一册</td>
                </tr>
                <tr>
                    <td>M2B</td>
                    <td>吴筱瑛/黄淑君</td>
                    <td>《中文》第一册</td>
                </tr>
                <tr>
                    <td>M3A</td>
                    <td>张蕾/何燕</td>
                    <td>《中文》第二册</td>
                </tr>
                <tr>
                    <td>M4A</td>
                    <td>汤丽娜/李艳珍</td>
                    <td>人教版小学语文</td>
                </tr>
                <tr>
                    <td>M4B</td>
                    <td>黄秋香</td>
                    <td>《中文》第三册</td>
                </tr>
                <tr>
                    <td>M5A</td>
                    <td>邱瑾</td>
                    <td>《中文》第四册</td>
                </tr>
                <tr>
                    <td>M5B</td>
                    <td>张静媛</td>
                    <td>《中文》第四册</td>
                </tr>
                <tr>
                    <td>M6A</td>
                    <td>郑秀华/陈红霞</td>
                    <td>《中文》第五册</td>
                </tr>
                <tr>
                    <td>M6B</td>
                    <td>王克澜</td>
                     <td>《中文》第五册</td>
                </tr>
                <tr>
                    <td>M7</td>
                    <td>刘虹</td>
                    <td>《中文》第六册</td>
                </tr>
                <tr>
                    <td>M8</td>
                    <td>王晓丽/魏珊屏</td>
                    <td>《中文》第七册</td>
                </tr>
                <tr>
                    <td>Advanced</td>
                    <td>张纲纲</td>
                    <td>自编</td>
                </tr>
                <tr>
                    <td>YCT</td>
                    <td>朱琳</td>
                     <td>自编</td>
                </tr>
                <tr>
                    <td>IGCSE-Edexcel</td>
                    <td>刘永勤/许钰</td>
                     <td>自编</td>
                </tr>
                <tr>
                    <td>IGCSE-CIE</td>
                    <td>王彩云/杨松</td>
                    <td>自编</td>
                </tr>
                <tr>
                    <td>A level</td>
                    <td>姜红敏</td>
                    <td>自编</td>
                </tr>
                <tr>
                    <td>Adult</td>
                    <td>洪燕芳</td>
                    <td>自编</td>
                </tr>
            </tbody> */}
        </Table>
    )
}
export default SchoolClasses;