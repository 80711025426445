function Resources() {
  return (
    <div className="resourcesDiv">
      <div className="resourcesSubDiv">
        <h2> Our textbook website【中文】教材网站 </h2>
        <a href="http://www.hwjyw.com/hwjc.html"  target="_blank"  rel="noopener noreferrer">http://www.hwjyw.com/hwjc.html</a>
      </div>

      <div className="resourcesSubDiv">
        <h2> Other textbook website (HanYu)【汉语】教材网站</h2>
        <a href="http://www.bbc.co.uk/languages/chinese/" target="_blank"  rel="noopener noreferrer">http://www.bbc.co.uk/languages/chinese/</a>  <br />
        <a href="http://cn.yes-chinese.com/zh-cn/course/langlang/index.do?level=A" target="_blank"  rel="noopener noreferrer">http://cn.yes-chinese.com/zh-cn/course/langlang/index.do?level=A</a><br />
        <a href="http://cn.yes-chinese.com/" target="_blank"  rel="noopener noreferrer">http://cn.yes-chinese.com/</a>
      </div>

      <div className="resourcesSubDiv">
        <h2>有趣的文化学习资源</h2>
        <h4>中华文化常识，猜谜语, 论语， 成语等的学习资源</h4>
        <a href="https://drive.google.com/drive/folders/1tSBzfI0T06ZfFIed4xUl7x19AsMFLWBD?usp=sharing" target="_blank"  rel="noopener noreferrer">https://drive.google.com/drive/folders/1tSBzfI0T06ZfFIed4xUl7x19AsMFLWBD?usp=sharing</a><br />
        <p></p>
        <a href="https://mp.weixin.qq.com/s?__biz=MjM5MTc0MDU2NA==&mid=2651298494&idx=1&sn=33accc1f3e8751e64951c787acb3bc75&chksm=bd43dd458a34545358717f1f435088c2e6b27bc804bf4327822611ccbbb262b97b9b8609145e&mpshare=1&scene=1&srcid=&sharer_sharetime=1581500290082&sharer_shareid=ac15ff39d150160b3f44c7d2e909a4c9#rd" target="_blank"  rel="noopener noreferrer">
          云游故宫</a>
      </div>

      <div className="resourcesSubDiv">
        <h2>For the beginners</h2>
        <a href="http://www.languageguide.org/mandarin/vocabulary/" target="_blank"  rel="noopener noreferrer">http://www.languageguide.org/mandarin/vocabulary/</a><br />
        <a href="http://www.creativechinese.com/"  target="_blank"  rel="noopener noreferrer">http://www.creativechinese.com/</a>
      </div>


      <div className="resourcesSubDiv">
        <h2>考试资源 Past papers resource</h2>
        <a href="http://qualifications.pearson.com/en/qualifications.html" target="_blank"  rel="noopener noreferrer">http://qualifications.pearson.com/en/qualifications.html</a>
      </div>
 
      <div className="resourcesSubDiv">
        <h2>中文教育机构 The organisation of Chinese Language Education</h2>
         <a href="http://www.ukapce.org" target="_blank"  rel="noopener noreferrer">http://www.ukapce.org</a><br />
        <a href="http://ukfcs.info/en/" target="_blank"  rel="noopener noreferrer">http://ukfcs.info/en/</a>
      </div>

      <div className="resourcesSubDiv">
        <h2>HSK 考试资源 HSK Revise Materials</h2>
        <a href="http://www.chinesetest.cn/godownload.do" target="_blank"  rel="noopener noreferrer">http://www.chinesetest.cn/godownload.do</a><br />
        <a href="http://www.chinaeducenter.com/en/hsk/hskmaterials.php" target="_blank"  rel="noopener noreferrer">http://www.chinaeducenter.com/en/hsk/hskmaterials.php</a>
      </div>

      <div className="resourcesSubDiv">
        <h2>Pre-GCSE, GCSE level Resources</h2>
        <h4>在quizlet在玩（学习最常用的700个字） </h4>
        <a href="https://quizlet.com/class/13274073/" target="_blank"  rel="noopener noreferrer">https://quizlet.com/class/13274073/</a>
      </div>







    </div>
  );
}

export default Resources;

